/**
 * Silicon | Multipurpose Bootstrap Template & UI Kit
 * Copyright 2023 Createx Studio
 * Theme core scripts
 *
 * @author Createx Studio
 * @version 1.6.0
 */

// Import Bootstrap and expose it globally
//
// Why this is needed:
// 1. Ensure Bootstrap's JavaScript components (e.g., Modal, Tooltip) are available globally across the application.
// 2. Compatibility with legacy code or scripts that rely on Bootstrap being available in the global scope.
// 3. Avoid repetitive imports and potential duplication by centralizing the import here.
//
// Note: While importing the entire Bootstrap bundle includes all components, consider importing only the necessary
// components
// for performance optimization if file size becomes a concern in the future.

import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

window.bootstrap = bootstrap;

// Other theme components and custom scripts
import './components/sticky-navbar';
import './components/scroll-top-button';
import './components/password-visibility-toggle';
import './components/element-parallax';
import './components/parallax';
import './components/carousel';
import './components/gallery';
import './components/range-slider';
import './components/form-validation';
import './components/input-formatter';
import './components/tooltip';
import './components/popover';
import './components/toast';
import './components/video-button';
import './components/price-switch';
import './components/masonry-grid';
import './components/subscription-form';
import './components/hover-animation';
import './components/audio-player';
